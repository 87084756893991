export default [
  {
    title: "Dashboard",
    route: "dashboard",
    auth: [1, 2],
    icon: "bi bi-columns-gap",
  },
  {
    title: "Online News",
    icon: "bi bi-globe-americas",
    auth: [1, 2],
    children: [
      {
        title: "Fixing",
        route: "fixing",
        auth: [1, 2],
        icon: "bi bi-tools",
      },
      {
        title: "Error",
        route: "error",
        auth: [1, 2],
        icon: "bi bi-x-circle",
        type: "child",
      },
      {
        title: "Missing Class",
        route: "missingclass",
        auth: [2],
        icon: "bi bi-exclamation-circle",
        type: "child",
      },
      {
        title: "Invalid Date",
        route: "invalid-date",
        auth: [1, 2],
        icon: "bi bi-calendar-x",
        type: "child",
      },
      {
        title: "No Article",
        route: "no-article",
        auth: [1, 2],
        icon: "bi bi-file-earmark-x",
        type: "child",
      },
      {
        title: "No Content",
        route: "no-content",
        auth: [1, 2],
        icon: "bi bi-journal-x",
        type: "child",
      },
      {
        title: "Related Link",
        route: "related-link",
        auth: [1, 2],
        icon: "bi bi-link-45deg",
        type: "child",
      },
      {
        title: "Redirected",
        route: "redirected",
        auth: [1, 2],
        icon: "bi bi-repeat",
        type: "child",
      },
      {
        title: "Unavailable",
        route: "unavailable",
        auth: [2],
        icon: "bi bi-slash-circle",
        type: "child",
      },
    ],
  },
  {
    title: "Tiktok",
    route: "tiktok-logs",
    auth: [1, 2],
    icon: "bi bi-tiktok",
  },
  {
    title: "Forum",
    route: "forum-logs",
    auth: [1, 2],
    icon: "bi bi-chat-text",
  },
  {
    title: "SIPUTRA",
    route: "media-siputra",
    auth: [1, 2],
    icon: "bi bi-clipboard-plus",
  },
  // {
  //   title: "Virtual Machine",
  //   route: "virtual-machine",
  //   auth: [1],
  //   icon: "bi bi-hdd-rack",
  // },
  {
    title: "Scraping Schedule",
    route: "scraping-schedule",
    auth: [1],
    icon: "bi bi-calendar3",
  },
  {
    title: "Log Scraper Tier 1",
    route: "log-scraper-tier-1",
    auth: [1],
    icon: "bi bi-globe",
  },
  {
    title: "Log Tier Media",
    route: "log-media-tier",
    auth: [1],
    icon: "bi bi-card-list",
  },
  {
    title: "Master Data",
    icon: "bi bi-gear-wide-connected",
    auth: [1],
    children: [
      {
        title: "Scraper Online News",
        route: "scraper",
        auth: [1],
        icon: "bi bi-card-list",
        type: "child",
      },
      {
        title: "Klasifikasi Masalah",
        route: "problem-classification",
        auth: [1],
        icon: "bi bi-folder",
        type: "child",
      },
      {
        title: "Grafana",
        route: "grafana",
        auth: [1],
        icon: "bi bi-server",
        type: "child",
      },

      {
        title: "Users",
        route: "users",
        auth: [1],
        icon: "bi bi-person-lines-fill",
        type: "child",
      },
    ],
  },
];
