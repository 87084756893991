<template>
  <b-modal
    id="modal-setting"
    ok-title="Kembali"
    size="md"
    hide-footer
    no-close-on-backdrop
  >
    <!-- custom modal title -->
    <template #modal-title>
      <div class="d-flex align-items-center">
        <feather-icon size="20" icon="SettingsIcon" class="mr-05" />
        <span class="fs-18">Pengaturan</span>
      </div>
    </template>
    <div class="row">
      <!-- server vm -->
      <div class="col-md-12">
        <b-card no-body class="h-100">
          <b-card-header>
            <span class="fs-16 font-weight-bolder">Penggunaan Server</span>
          </b-card-header>
          <b-card-body>
            <b-alert
              variant="success"
              :show="is_success"
              class="px-1"
              style="padding-top: 5px; padding-bottom: 5px"
            >
              <span class="d-flex align-items-center">
                <feather-icon icon="CheckCircleIcon" class="mr-05" />
                <small>
                  Berhasil! Limit penggunaan server diubah menjadi {{ limit }}%
                </small>
              </span>
            </b-alert>
            <b-alert
              variant="danger"
              :show="is_error"
              class="px-1"
              style="padding-top: 5px; padding-bottom: 5px"
            >
              <span class="d-flex align-items-center">
                <feather-icon icon="CheckCircleIcon" class="mr-05" />
                <small> Gagal! Terjadi kesalahan saat mengubah limit </small>
              </span>
            </b-alert>
            <validation-observer ref="serverLimit">
              <b-form @submit.prevent="updateLimit">
                <!-- server usage -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Limit Penggunaan Server"
                    rules="required|between:0,100"
                  >
                    <label for="limit-server-usage">
                      Limit Penggunaan Server (%)
                    </label>
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="PercentIcon" />
                      </b-input-group-prepend>

                      <b-form-input
                        v-model="limit"
                        id="limit-server-usage"
                        type="number"
                        placeholder="Limit Penggunaan Server"
                      />
                    </b-input-group>
                    <small class="text-danger" style="font-size: 10px">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- button simpan -->
                <b-button
                  type="submit"
                  variant="outline-info"
                  size="sm"
                  class="float-right"
                >
                  Simpan
                </b-button>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {
  BModal,
  VBModal,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BAlert,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, between } from "@validations";
import useJwt from "@/auth/jwt/useJwt";

export default {
  name: "profile",
  data() {
    return {
      is_success: false,
      is_error: false,
      limit: 10,
      required,
      between,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BAlert,
  },
  mounted() {
    this.getPersentageLimit();
  },
  methods: {
    getPersentageLimit() {
      useJwt.axiosIns
        .get("scraper/get-persentase-usage")
        .then((res) => {
          this.limit = res.data.value;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateLimit() {
      this.$refs.serverLimit.validate().then((success) => {
        if (success) {
          useJwt.axiosIns
            .put("scraper/edit-persentase-usage", {
              data: {
                value: parseInt(this.limit),
              },
            })
            .then(() => {
              this.is_success = true;
              setTimeout(() => {
                this.is_success = false;
              }, 2000);
            })
            .catch(() => {
              this.is_error = true;
              setTimeout(() => {
                this.is_error = false;
              }, 2000);
              this.getPersentageLimit();
            });
        }
      });
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>